<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4 mb-3">
        <div class="row" v-if="current_point !== null">
            <div class="col-12">
                <form @submit.prevent="postGrammar()" class="row">
                    <div class="col-12">
                        Set Question for Point - {{current_point.point}}
                    </div>
                    <div class="form-group col-10">
                        <select v-model="quest_id" class="form-control" required>
                            <option value="">-- Choose Question --</option>
                            <option v-for="(item, i) in quest" :key="i" :value="item">Quest {{i + 1}}</option>
                        </select>
                    </div>
                    <div class="form-group col-2">
                        <button class="btn btn-lg btn-success fw-500">Submit</button>
                    </div>
                    <div class="col-12" v-if="quest_id">
                        <div class="card p-0 mb-3">
                            <div class="card-body text-center" v-if="quest_id.quest_type == 1">
                                <video class="w-50 h-auto" controls>
                                    <source :src=" quest_id.path" type="video/mp4">
                                </video>
                            </div>
                            <div class="card-body" v-if="quest_id.quest_type == 2">
                                <audio class="w-100" controls>
                                    <source :src=" quest_id.path" type="audio/mpeg">
                                </audio>
                            </div>
                            <div class="card-body ql-editor">
                                <div v-html="quest_id.question"></div>
                            </div>
                            <div v-if="quest_id.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                File Upload
                            </div>
                            <div v-if="quest_id.submission_type == 2" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                                Essay
                            </div>
                            <div v-if="quest_id.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                                <table class="table m-0">
                                    <tr v-for="(opt, j) in quest_id.option" :key="j">
                                        <td width="5%" v-if="opt.id == quest_id.answer" class="bg-success"></td>
                                        <td width="5%" v-else class="bg-grey"></td>
                                        <td>{{opt.option}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row" v-if="!isLoad">
            <div class="col-12" v-if="grammar.length">
                <table class="table table-hover table-bordered text-center">
                    <thead>
                        <tr>
                            <th>Language Ability</th>
                            <th>Question</th>
                            <th>Question</th>
                            <th>Question</th>
                            <th>Question</th>
                            <th>Action</th>
                        </tr>
                        <tr v-for="(item, i) in grammar" :key="i">
                            <td>{{item.point}}</td>
                            <td><a v-if="item.goq[0]" @click="showModal(item.goq[0])" href="javascript:void(0)" class="btn btn-sm btn-info"><i class="fas fa-info"></i></a></td>
                            <td><a v-if="item.goq[1]" @click="showModal(item.goq[1])" href="javascript:void(0)" class="btn btn-sm btn-info"><i class="fas fa-info"></i></a></td>
                            <td><a v-if="item.goq[2]" @click="showModal(item.goq[2])" href="javascript:void(0)" class="btn btn-sm btn-info"><i class="fas fa-info"></i></a></td>
                            <td><a v-if="item.goq[3]" @click="showModal(item.goq[3])" href="javascript:void(0)" class="btn btn-sm btn-info"><i class="fas fa-info"></i></a></td>
                            <td>
                                <a v-if="item.add" @click="setCurrentPoint(item)" href="javascript:void(0)" style="opacity: 0.65;" class="btn btn-sm btn-success"><i class="fas fa-plus"></i></a>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="col-12 text-center" v-else>
                <img :src="'/images/empty.png'" alt="" width="300">
                <h1 class="text-muted">Data don't exist</h1>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-12 text-center py-3">
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <modal name="detail-modal"
            :width="'60%'"
            :height="'auto'"
            @before-close="beforeClose">
            <div class="container-fluid">
                <div class="row mb-0 mt-2 py-2">
                    <div class="col-12 px-4 py-0">
                        <a @click="closeModal()" href="javascript:void(0)" class="float-right"><i class="fas fa-times text-danger"></i></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr>
                    </div>
                </div>
                <div class="row align-item-center justify-content-center pb-3" v-if="current_quest != null">
                    <div class="col-11 card p-0 mb-3">
                        <div class="card-title border-bottom bg-grey px-3 py-2 mb-0">
                            <a class="float-right font-xs" @click="deleteAlert(current_quest.id)" href="javascript:void(0)"><i class="fas fa-trash text-danger"></i></a>
                        </div>
                        <div class="card-body text-center" v-if="current_quest.quest.quest_type == 1">
                            <video class="w-50 h-auto" controls>
                                <source :src=" current_quest.quest.path" type="video/mp4">
                            </video>
                        </div>
                        <div class="card-body" v-if="current_quest.quest.quest_type == 2">
                            <audio class="w-100" controls>
                                <source :src=" current_quest.quest.path" type="audio/mpeg">
                            </audio>
                        </div>
                        <div class="card-body ql-editor">
                            <div v-html="current_quest.quest.question"></div>
                        </div>
                        <div style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                            
                        </div>
                        <div v-if="current_quest.quest.submission_type == 1" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                            File Upload
                        </div>
                        <div v-if="current_quest.quest.submission_type == 2" style="width: 100wh" class="card-body p-3 border-top bg-lightgrey">
                            Essay
                        </div>
                        <div v-if="current_quest.quest.submission_type == 3" style="width: 100wh" class="card-body p-0 border-top bg-lightgrey">
                            <table class="table m-0">
                                <tr v-for="(opt, j) in current_quest.quest.option" :key="j">
                                    <td width="5%" v-if="opt.id == current_quest.quest.answer" class="bg-success"></td>
                                    <td width="5%" v-else class="bg-grey"></td>
                                    <td>{{opt.option}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['newData'], 
    watch:{
        newData: function(newVal){
            if(newVal) {
                this.getQuest();
            }
        }
    },
    data(){
        return{
            id: this.$route.params.idAssignment,
            slug: this.$route.params.idCourse,
            isLoad: true,
            current_point: null,
            grammar: [],
            quest: [],
            quest_id: '',
            current_quest: null
        }
    },
    created(){
        this.getGrammar();
        this.getQuest();
    },
    methods:{
        showModal(item){
            this.current_quest = item
            this.$modal.show('detail-modal')
        },
        closeModal(){
            this.$modal.hide('detail-modal')
        },
        beforeClose() {
            this.current_quest = []
            this.getGrammar()
        },
        setCurrentPoint(index) {
            this.current_point = index
        },
        async getGrammar(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/grammar?id=${this.id}&slug=${this.slug}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.grammar = res.data.data
                this.isLoad = false
            }).catch(err => {
                console.log(err.response)
            })
        },
        async postGrammar(){
            var data = {
                slug: this.slug,
                assignment_id: this.id,
                quest_id: this.quest_id.id,
                grammar_id: this.current_point.id

            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment/grammar`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                if (res.data.success) {
                    this.isLoad = true
                    this.current_point =  null
                    this.quest_id = ''
                    this.getGrammar();
                    this.getQuest();
                    this.$swal({
                        toast: true,
                        title: 'Language Ability!',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Language Ability!',
                    text: 'Failed add Language Ability to Question',
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async() => {
                    this.deleteGrammar(id)
                },
            }).then((result) => {
                if(result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Language Ability',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteGrammar(id){
            var data = {
                slug: this.slug,
                assignment_id: this.id,
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/assignment/grammar`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.closeModal()
                this.isLoad = true
                this.current_point =  null
                this.current_quest = null
                this.quest_id = ''
                this.getGrammar();
                this.getQuest();
                return res
            }).catch(err => {
                return err
            })
        },
        async getQuest(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/assignment/quest?id=${this.id}&slug=${this.slug}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.quest = res.data.data
            }).catch(err => {
                console.log(err.response)
            })
        },
    }
}
</script>